import { useCallback, useMemo } from 'react'
import cn from 'classnames'
import Link from 'next/link'
import useAuth from 'hooks/authentication/useAuth'
import useScrollDirectionForWindow from 'hooks/useScrollDirectionForWindow'
import { trackEvent } from 'services/analytics/google'
import { useAskingRoleModals } from 'shared/hooks/useAskingRoleModals'
import dynamic from 'next/dynamic'
import HeaderSearchBar from './HeaderSearchBar'
import useAppSettings from 'shared/hooks/useAppSettings'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { useBoolean } from 'usehooks-ts'
import useAppLayout from 'shared/hooks/useAppLayout'
import CustomImage from 'shared/components/CustomImage'
import useCheckingRole from 'shared/hooks/data-fetching/useCheckingRole'
import { trackAmplitudeCustomEvent } from 'services/analytics/amplitude'
import { useRouter } from 'next/router'
import convertPathToPageName from 'shared/utils/convertPathToPageName'

const placeholder = 'Try "Dreamy" artworks'

const GuestMobileMenu = dynamic(() => import('./GuestMobileMenu'))
const UploadMenu = dynamic(() => import('./UploadMenu'))
const MobileSearchbar = dynamic(() => import('./MobileSearchbar'))

type HeaderProps = {
  activePage?: 'discover'
  transparent?: boolean
  textWhite?: boolean
  hideHeader?: boolean
  // for use in discover page
  currentSearchText?: string
  onSearch?: (text: string) => void
}

const Header = (props: HeaderProps) => {
  const { activePage, hideHeader, currentSearchText, onSearch } = props
  const { appSettings } = useAppSettings()
  const { pathname } = useRouter()

  const { isLoggedIn, authUser: myProfile } = useAuth()
  const { isUserCollector: isCollector, isUserIA: isIndependentAdvisor } = useCheckingRole(myProfile?.role)

  const { showAreYouArtistModal, showApplyToBeCohartAdvisorModal } = useAskingRoleModals()
  const scrollDirectionWindow = useScrollDirectionForWindow()
  const {
    value: isMobileSearchbarOpen,
    setTrue: openMobileSearchbar,
    setFalse: closeMobileSearchbar,
  } = useBoolean(false)

  const shouldHideHeader = hideHeader || scrollDirectionWindow === 'down'
  const { toggleMobileSidebar, appLayout } = useAppLayout({ fullHeightSidebar: shouldHideHeader })
  const transparent = !appLayout.isMobileSidebarVisible && props.transparent
  const textWhite = !appLayout.isMobileSidebarVisible && props.textWhite

  const avatar = (myProfile?.coverImage as string) || '/icons/default-ava.svg'
  const username = myProfile?.username || ''
  const textColorClass = textWhite ? 'text-white' : 'text-black'
  const bgColorClass = transparent ? 'bg-transparent' : 'bg-white shadow'

  const handleUploadCTAClick = useCallback(() => {
    if (isCollector) {
      showAreYouArtistModal()
    } else if (isIndependentAdvisor) {
      showApplyToBeCohartAdvisorModal()
    }
  }, [isCollector, isIndependentAdvisor, showAreYouArtistModal, showApplyToBeCohartAdvisorModal])

  const renderUploadButton = useMemo(() => {
    if (!isLoggedIn) return null
    return <UploadMenu onCTAClick={handleUploadCTAClick} />
  }, [isLoggedIn, handleUploadCTAClick])

  const onLogoClick = useCallback(() => {
    trackEvent({ action: 'logo_clicked' })
  }, [])

  const handleOpenMobileSearchbar = useCallback(() => {
    openMobileSearchbar()
    trackAmplitudeCustomEvent('discover_search_icon_clicked', { screen: convertPathToPageName(pathname) })
  }, [openMobileSearchbar, pathname])

  if (!appSettings.isHeaderVisible) return null // hide header if it is set to be hidden

  return (
    <>
      <header
        className={cn(
          'fixed left-0 top-0 z-50 flex h-[80px] w-full items-center px-7 !font-monument-grotes transition-all sm:px-10',
          shouldHideHeader && '!-top-[80px]',
          bgColorClass,
          textColorClass,
        )}
      >
        <nav className="flex h-full w-full flex-row items-center justify-between font-monument-grotes uppercase">
          <div className="flex w-full items-center gap-2 lg:gap-8">
            <Link
              passHref
              href={isLoggedIn ? '/homepage' : '/'}
              onClick={onLogoClick}
              className="font-beni text-5xl uppercase"
            >
              Cohart
            </Link>

            <div className="relative flex h-[27px] w-[64px] items-center lg:h-10 lg:w-[113px]">
              <Link
                passHref
                href="/discover"
                className={cn(
                  'z-10 rounded-full border border-black/10 bg-[#FDFDFD] px-3 py-[6px] text-[10px] ',
                  'font-medium normal-case text-black transition-shadow hover:drop-shadow-lg lg:px-6 lg:py-2 lg:text-base',
                )}
              >
                Discover
              </Link>

              {activePage === 'discover' && (
                <div className="absolute left-[2px] top-1 h-full w-full rounded-full bg-mint-green"></div>
              )}
            </div>

            <button className="lg:hidden" onClick={handleOpenMobileSearchbar}>
              <MagnifyingGlassIcon className="h-6 w-6" />
            </button>

            <div className="hidden lg:block">
              <HeaderSearchBar
                screen={convertPathToPageName(pathname)}
                placeholder={placeholder}
                isTransparent={transparent}
                currentSearchText={currentSearchText}
                onChange={onSearch}
              />
            </div>
          </div>

          <div className="flex items-center gap-8">
            <div className="inline-flex gap-2 lg:gap-6">
              <div className="flex items-center">
                <Link
                  className={cn(
                    'z-10 flex items-center rounded-full border border-black/10 bg-[#FDFDFD] px-3 py-2 text-[10px] leading-[100%]',
                    'font-medium normal-case text-black transition-shadow hover:drop-shadow-lg lg:px-6 lg:text-base',
                  )}
                  href="/news"
                >
                  Blog
                </Link>
              </div>
              <div className="flex items-center space-y-2">{renderUploadButton}</div>
              <div className="flex items-center">
                {/* guest menu - both mobile and desktop */}
                {!isLoggedIn && (
                  <div className="flex items-center gap-4">
                    {/* desktop links */}

                    <Link
                      className={cn(
                        'hidden items-center justify-center rounded-full bg-white px-5 py-1 text-center text-base normal-case',
                        'text-black transition-all hover:rotate-[-4deg] hover:drop-shadow-lg lg:flex',
                      )}
                      href="/auth/login"
                    >
                      Login
                    </Link>

                    <Link
                      className={cn(
                        'hidden items-center justify-center rounded-full border border-black bg-[#A8FF8F] px-5 py-1 text-center',
                        'text-base normal-case text-black transition-all hover:rotate-[-4deg] hover:drop-shadow-lg lg:flex',
                      )}
                      href="/auth/sign-up"
                    >
                      Join
                    </Link>

                    {/* mobile menu */}
                    <GuestMobileMenu textColorClass={textColorClass} />
                  </div>
                )}

                {/* signed-in user menu - both mobile and desktop */}
                {isLoggedIn && (
                  <div className="cursor-pointer">
                    {/* show avatar on the desktop */}
                    <Link href={`/u/${username}`} className="hidden items-center gap-3 font-inter lg:flex">
                      <p className="font-medium normal-case">@{username}</p>
                      <div className="relative h-[46px] w-[46px] overflow-hidden rounded-full border border-black/10 ">
                        <CustomImage
                          src={avatar}
                          placeholder="blur"
                          blurDataURL="/images/default.png"
                          fill
                          className="object-cover"
                          alt="ava"
                        />
                      </div>
                    </Link>

                    {/* show burgur menu on the mobile */}
                    <div
                      role="button"
                      onClick={toggleMobileSidebar}
                      className={cn('relative size-6 focus:outline-none lg:hidden', textColorClass)}
                    >
                      <div className="absolute left-1/2 top-1/2 block w-6 -translate-x-1/2 -translate-y-1/2 scale-75 transform">
                        <span
                          aria-hidden="true"
                          className={cn(
                            'absolute block h-[1px] w-6 transform bg-current transition duration-300 ease-in-out',
                            appLayout.isMobileSidebarVisible ? 'rotate-45' : '-translate-y-2.5',
                          )}
                        />
                        <span
                          aria-hidden="true"
                          className={cn(
                            'absolute block h-[1px] w-6 transform bg-current transition duration-300 ease-in-out',
                            appLayout.isMobileSidebarVisible ? 'opacity-0' : '',
                          )}
                        />
                        <span
                          aria-hidden="true"
                          className={cn(
                            'absolute block h-[1px] w-6 transform bg-current transition duration-300 ease-in-out',
                            appLayout.isMobileSidebarVisible ? '-rotate-45' : 'translate-y-2.5',
                          )}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </nav>
      </header>
      {isMobileSearchbarOpen && (
        <MobileSearchbar
          isOpen={isMobileSearchbarOpen}
          onClose={closeMobileSearchbar}
          placeholder={placeholder}
          currentSearchText={currentSearchText}
          onChange={onSearch}
        />
      )}
    </>
  )
}

export default Header
